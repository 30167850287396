.appBarContainer {
  height: 50px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
}

.appBarContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.appBarContents {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

@media (max-width: 768px) {
  .appBarContents {
    display: none;
  }
}

.mobileView {
  display: none;
}

@media (max-width: 768px) {
  .mobileView {
    display: block;
    cursor: pointer;
  }
}

.profile {
  border-radius: 50%;
  background-color: #ddd;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
}

.appIcon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.springBoardContents {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.springHeader {
  font-size: 16px;
  color: #696969;
  font-family: "Poppins", sans-serif;
}

.springContents {
  color: #333333;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  margin-top: 2px;
  font-style: medium;
}

.menuItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 4px;
}

.menuItem:hover {
  background-color: #f5f5f5 !important;
}

.springContents:hover {
  background-color: #f5f5f5 !important;
}

.userName {
  color: #333333;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

.userEmail {
  color: #696969;
  width: 230px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.logoutContainer {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-left: 10px;
  font-family: "Poppins", sans-serif;
  margin-top: 10px;
  color: #696969;
}
