.cardConatiner {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 16px;
  margin-top: 20px;
}

@media (max-width: 1300px) {
  .cardConatiner {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 900px) {
  .cardConatiner {
    grid-template-columns: 1fr 1fr;
  }
}
.userName {
    font-family: "Roboto Medium", "Roboto", sans-serif;
    font-size: 22px;
    font-weight: 500;
    color: #333333;
    text-transform: capitalize;
    margin-bottom: 4px;
    font-weight: bold;
    line-height: 28px;
    margin-left: 8px;
}

@media (max-width: 650px) {
  .cardConatiner {
    grid-template-columns: 1fr;
  }
}
