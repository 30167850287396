.cardContainer {
  height: 290px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 28px;
  border: 1px solid #ddd;
}

.icon {
  width: 80px;
  height: 80px;
  display: flex;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.title {
  font-size: 26px;
  font-family: "Roboto" sans-serif;
  font-weight: bold;
  margin-top: 12px;
  margin-bottom: 12px;
}
.content {
  font-size: 16px;
  color: #4d4d4d;
  height: auto;
  
}
.button {

  border: 1px solid #ddd;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  color: #3e78da;
  bottom: 24px;
  position: absolute;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 24px;
  margin-right: 24px;

}
.button:hover {
  color: white;
  cursor: pointer;
  background-color: #3e78da;
}
